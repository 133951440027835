<template>
  <TemplateBlock
    mod-class
    content-class="shop-product"
    title-page="Интернет-магазин"
  >
    <div class="w-100 h-100 shop-page d-flex flex-column">
      <div
        class="page__title mb-3 px-3 py-3 d-flex justify-content-start align-items-center"
      >
        <router-link to="/shop">
          <img height="56" :src="logoImg" class="pseudo-link filters__logo" />
        </router-link>
        <div class="text ml-3">Корзина</div>
        <div
          v-if="products.length"
          class="badge ml-2 d-flex align-items-center justify-content-center"
        >
          {{ products.length }}
        </div>
      </div>
      <div
        class="page__content container d-flex align-items-start justify-content-between flex-1"
      >
        <vuescroll>
          <div class="page__items-list mr-3" v-if="!isContentLoading">
            <div
              v-if="products.length"
              class="box page__actions d-flex justify-content-between align-items-center"
            >
              <div class="pseudo-link" @click="doSelectAllProducts">
                <img :src="checkIcon" />
                <span class="ml-2 text_sm">Выбрать все</span>
              </div>
              <div
                v-if="hasSelectedItems"
                class="pseudo-link text_sm"
                @click="removeSelectedProducts"
              >
                Удалить выбранные
              </div>
            </div>
            <div class="mt-4" v-if="products.length">
              <div
                v-for="item in productsWithFullInfo"
                :key="item.id"
                class="mb-3 box"
              >
                <ShopCartItem
                  :product="item"
                  :selected="item.selected"
                  :itemsCount="item.count"
                  @remove="removeProduct"
                  @selected-change="toggleSelected"
                  @change-count="recalculateTotalPrice"
                />
              </div>
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-center w-full font-bold box"
            >
              Корзина пуста
            </div>
            <div v-if="!products.length" class="mt-3">
              <span class="pseudo-link" @click="goToShop">
                Вернуться к покупкам
              </span>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-start justify-content-center mt-3 w-full"
          >
            <b-spinner></b-spinner>
          </div>
        </vuescroll>
        <div class="page__info-sum box ml-3">
          <ShopCartInfoSum
            :products="products"
            :summ="totalSumm"
            :discount="totalDiscount"
            :discount-percent="5"
            @start-order-create="startOrderCreate"
          />
        </div>
      </div>
    </div>

    <PurchaseMethodSelectModal
      v-if="isPurchaseSelectModalVisible"
      @close="hidePurchaseMethodSelectModal"
    />
  </TemplateBlock>
</template>

<script>
import RequestManager from "@/function/request/RequestManager";
import CheckIcon from "@/resource/img/check.svg";
import ShopCartInfoSum from "@/components/Shop/ShopCartInfoSum.vue";
import ShopCartItem from "@/components/Shop/ShopCartItem.vue";
import ShoppingCart from "@/utils/shopping-cart";
import TemplateBlock from "@/components/TepmplateBlock.vue";
import PurchaseMethodSelectModal from "@/components/Shop/ShopPurchaseMethodSelectModal.vue";
import LogoImg from "@/resource/img/home-icon/printer.svg";
import vuescroll from "vuescroll";

export default {
  name: "ShopCart",
  components: {
    ShopCartInfoSum,
    ShopCartItem,
    TemplateBlock,
    PurchaseMethodSelectModal,
    vuescroll
  },
  data() {
    return {
      logoImg: LogoImg,
      products: [],
      productsWithFullInfo: [],
      isContentLoading: false,
      checkIcon: CheckIcon,
      totalSumm: 0,
      isPurchaseSelectModalVisible: false,
      currentUser: undefined
    };
  },
  computed: {
    hasSelectedItems() {
      let res = false;
      this.productsWithFullInfo.forEach(
        product => (res = res || product.selected)
      );
      return res;
    },
    totalDiscount() {
      if (this.currentUser && this.currentUser.id) {
        return this.totalSumm * 0.05;
      }
      return 0;
    }
  },
  methods: {
    calculateTotalSumm() {
      let summ = 0;
      if (this.products && this.products.length) {
        this.products.forEach(product => {
          const foundProductInfo = this.productsWithFullInfo.find(
            prd => prd.id === product.product_id
          );
          if (foundProductInfo) {
            summ = summ + foundProductInfo.price * product.count;
          }
        });
      }
      this.totalSumm = summ;
    },
    toggleSelected(productId) {
      const foundProduct = this.productsWithFullInfo.find(
        product => product.id === productId
      );
      if (foundProduct) {
        foundProduct.selected = !foundProduct.selected;
      }
    },
    doSelectAllProducts() {
      this.productsWithFullInfo.forEach(product => (product.selected = true));
    },
    removeProduct(productId) {
      const foundProductIdx = this.products.findIndex(
        product => product.product_id === productId
      );
      if (foundProductIdx > -1) {
        this.products.splice(foundProductIdx, 1);
      }
      const foundProductWithFullInfoIdx = this.productsWithFullInfo.findIndex(
        pwfi => pwfi.id === productId
      );
      if (foundProductWithFullInfoIdx > -1) {
        this.productsWithFullInfo.splice(foundProductWithFullInfoIdx, 1);
      }
      this.calculateTotalSumm();
      localStorage.setItem("cart_products", JSON.stringify(this.products));
    },
    removeSelectedProducts() {
      let notSelectedProducts = [];
      this.productsWithFullInfo.forEach(product => {
        if (!product.selected) {
          notSelectedProducts.push(product);
        }
      });
      this.productsWithFullInfo = notSelectedProducts.slice(0);
      const newProducts = [];
      for (let idx = 0; idx < this.products.length; idx++) {
        const foundProductWithFullInfoIdx = this.productsWithFullInfo.findIndex(
          pwfi => pwfi.id === this.products[idx].product_id
        );
        if (foundProductWithFullInfoIdx > -1) {
          newProducts.push(this.products[idx]);
        }
      }
      this.$set(this, "products", newProducts);
      this.calculateTotalSumm();
      localStorage.setItem("cart_products", JSON.stringify(this.products));
    },
    recalculateTotalPrice(data) {
      const { product, count } = data;
      const foundProduct = this.products.find(
        prd => prd.product_id === product
      );
      if (foundProduct) {
        foundProduct.count = count;
      }
      this.calculateTotalSumm();
      localStorage.setItem("cart_products", JSON.stringify(this.products));
    },
    goToShop() {
      this.$router.push("/shop");
    },
    showPurchaseMethodSelectModal() {
      this.isPurchaseSelectModalVisible = true;
    },
    hidePurchaseMethodSelectModal() {
      this.isPurchaseSelectModalVisible = false;
    },
    startOrderCreate() {
      const currentUserFromStorage = localStorage.getItem("currentUser");
      if (!currentUserFromStorage || !currentUserFromStorage.length) {
        this.showPurchaseMethodSelectModal();
      } else {
        this.$router.push("/order-payment");
      }
    }
  },
  created() {
    const currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    }
  },
  async mounted() {
    this.products = new ShoppingCart().getProducts();
    const productsIds = this.products.map(product => product.product_id);
    this.isContentLoading = true;
    if (productsIds.length) {
      let { items } = await RequestManager().productApi.listProduct({
        ids: productsIds
      });
      items.forEach(product => {
        product.technique = {};
        product.count = 1;
      });
      const techniqueIds = items.map(product => product.techniqueId);
      const techniqueItems = await RequestManager().techniqueApi.techniqueList({
        ids: techniqueIds
      });
      techniqueItems.items.forEach(tech => {
        const foundProduct = items.find(
          product => product.techniqueId === Number(tech.id)
        );
        if (foundProduct) {
          foundProduct.technique = tech;
        }
        const foundProductInProducts = this.products.find(
          prd => prd.product_id === foundProduct.id
        );
        if (foundProductInProducts) {
          foundProduct.count = foundProductInProducts.count;
        }
      });
      items.forEach(item => (item.selected = false));
      this.isContentLoading = false;
      this.$set(this, "productsWithFullInfo", items.splice(0));
      this.calculateTotalSumm();
    } else {
      this.isContentLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.page__title {
  font-weight: 600;

  .text {
    font-size: 24px;
  }
}

.shop-page {
  background-color: #f9f9ff;
}

.badge {
  --badge-padding-x: 0.65em;
  --badge-padding-y: 0.35em;
  --badge-font-size: 1em;
  --badge-font-weight: 700;
  --badge-color: #fff;
  --badge-border-radius: 4px;
  background-color: red;
  display: inline-block;
  padding: var(--badge-padding-y) var(--badge-padding-x);
  font-size: var(--badge-font-size);
  font-weight: var(--badge-font-weight);
  line-height: 1;
  color: var(--badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--badge-border-radius);
}

.box {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px 15px;
}

.pseudo-link {
  cursor: pointer;
  color: #2c698c;
  font-weight: 500;
}
.pseudo-link:hover {
  text-decoration: underline;
}

.page__items-list {
  flex: 1;
}

.text_sm {
  font-size: 0.9em;
}

.w-full {
  width: 100%;
}

.__vuescroll {
  height: calc(100vh - 210px) !important;
}

.flex-1 {
  flex: 1;
}
</style>
