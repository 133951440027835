<template>
  <section class="wrapper">
    <div class="pseudo-modal">
      <div class="d-flex justify-content-between pseudo-modal__header">
        <h5 class="mb-3 ml-1">
          Выберите способ покупки в интернет-магазине Aura
        </h5>
        <div class="c-pointer font-weight-bold" @click="close">x</div>
      </div>
      <div class="pseudo-modal__body">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <div class="purchase-item" @click="doNextStep('registration')">
            <div class="purchase-item__text">
              Зарегистрироваться в системе и получить скидку на Вашу покупку
            </div>
          </div>
          <div class="purchase-item" @click="doNextStep('auth')">
            <div class="purchase-item__text">
              Авторизоваться в Aura
            </div>
          </div>
          <div class="purchase-item" @click="doNextStep('none')">
            <div class="purchase-item__text">
              Совершить покупку без регистрации
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ShopPurchaseMethodSelectModal",
  methods: {
    close() {
      this.$emit("close");
    },
    doNextStep(param) {
      switch (param) {
        case "auth":
          localStorage.removeItem("shop-first");
          this.$router.push({
            name: "Auth",
            query: {
              redirect: "/order-payment"
            }
          });
          break;
        case "registration":
          localStorage.setItem("shop-first", "true");
          this.$router.push({
            name: "Registration",
            query: {
              redirect: "/order-payment"
            }
          });
          break;
        case "none":
          localStorage.removeItem("shop-first");
          this.$router.push("/order-payment");
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.pseudo-modal {
  background: var(--main-card-color);
  border-radius: 6px;
  padding: 20px;
  width: 480px;
  margin: auto auto;
  transform: translateY(50%);
}

.purchase-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5f1f5;
  border: 1px solid #e5f1f5;
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 46px;
  padding: 8px 16px;
  width: 350px;
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

.purchase-item:hover {
  border: 1px solid rgba(0, 107, 144, 0.7);
}
</style>
