<template>
  <div class="cart-item d-flex align-items-center w-100">
    <div class="cart-item__cb">
      <input type="checkbox" :checked="selected" @change="emitSelectedChange" />
    </div>
    <div class="cart-item__img ml-2">
      <img
        v-if="product.technique.files && product.technique.files.length"
        :src="product.technique.files[0].url"
        width="140"
        height="140"
        class="product__image"
      />
      <img
        v-else
        src="https://placehold.it/90x80"
        width="140"
        height="140"
        class="product__image"
      />
    </div>
    <div
      class="cart-item__title align-self-start font-semibold pseudo-link ml-2 flex-1"
      @click="goToProductPage"
    >
      {{ product.technique.title }}
    </div>
    <div
      class="cart-item__count d-flex justify-content-between align-self-stretch ml-2"
    >
      <div class="d-flex align-items-start flex-1">
        <div class="d-flex align-items-center">
          <img class="action-icon" :src="minusIcon" @click="decrementCount" />
          <span class="font-bold text_lg mx-2">{{ count }}</span>
          <img class="action-icon" :src="plusIcon" @click="incrementCount" />
        </div>
      </div>
      <div
        class="align-self-center text_sm pseudo-link"
        @click="emitRemoveProduct"
      >
        Удалить
      </div>
    </div>
    <div class="cart-item__price font-bold ml-2">
      {{ product.price | money }}
    </div>
  </div>
</template>

<script>
import MinusIcon from "@/resource/img/minus_form.svg";
import PlusIcon from "@/resource/img/plus_form.svg";

export default {
  name: "ShopCartItem",
  props: {
    product: Object,
    itemsCount: Number,
    selected: Boolean
  },
  data() {
    return {
      count: this.itemsCount || 1,
      minusIcon: MinusIcon,
      plusIcon: PlusIcon
    };
  },
  methods: {
    emitSelectedChange() {
      this.$emit("selected-change", this.product.id);
    },
    emitRemoveProduct() {
      this.$emit("remove", this.product.id);
    },
    incrementCount() {
      this.count++;
      this.$emit("change-count", {
        product: this.product.id,
        count: this.count
      });
    },
    decrementCount() {
      this.count--;
      if (this.count < 1) {
        this.emitRemoveProduct(this.product.id);
      }
      this.$emit("change-count", {
        product: this.product.id,
        count: this.count
      });
    },
    goToProductPage() {
      this.$router.push(`/shop/${this.product.id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-item {
  padding: 10px;
}

.font-semibold {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}

.flex-1 {
  flex: 1 !important;
}

.text_sm {
  font-size: 0.9em;
}
.text_lg {
  font-size: 1.4em;
  font-weight: bold;
}

.cart-item__count {
  flex-direction: column;
}

.action-icon {
  cursor: pointer;
}

.pseudo-link {
  cursor: pointer;
  color: #2c698c;
  font-weight: 500;
}
.pseudo-link:hover {
  text-decoration: underline;
}

.cart-item__price {
  width: 80px;
  text-align: center;
}
.cart-item__count {
  width: 90px;
  text-align: center;
}
</style>
