<template>
  <div class="order-info">
    <div class="order-info__header font_semibold mb-3 text_lg">
      Детали заказа
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div>
        <span class="mr-1">Товаров:</span>
        <span class="font_semibold">{{ productsCount }}</span>
      </div>
      <div class="font_semibold">{{ summ | money }}</div>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div>
        Скидка<span v-if="discount > 0" class="font_semibold"
          >, {{ discountPercent }}%</span
        >
      </div>
      <div class="font_semibold">{{ discount | money }}</div>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-2 text_lg">
      <div class="font_semibold">Итого</div>
      <div class="font_semibold">{{ (summ - discount) | money }}</div>
    </div>
    <div
      v-if="!hasOrder"
      class="mt-3 d-flex align-items-center justify-content-center"
    >
      <button
        class="button"
        :disabled="productsCount < 1"
        @click="handleStartOrderCreate"
      >
        Перейти к оформлению
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopCartInfoSum",
  props: {
    products: Array,
    summ: Number,
    discount: Number,
    discountPercent: Number,
    hasOrder: Boolean
  },
  computed: {
    productsCount() {
      let count = 0;
      this.products.forEach(prd => {
        count = count + prd.count;
      });
      return count;
    }
  },
  methods: {
    handleStartOrderCreate() {
      this.$emit("start-order-create");
    }
  }
};
</script>

<style lang="scss" scoped>
.order-info {
  min-width: 300px;
}

.font_semibold {
  font-weight: 600;
}

.text_lg {
  font-size: 1.1em;
}

.button {
  font-size: 12px;
  background-color: #2c698c;
  border-radius: 4px;
  color: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  font-weight: 600;
  border: none;
  padding: 0.75em 1em;
  min-width: 120px;
}

.button:hover {
  background-color: rgba(0, 107, 144, 0.7);
}

.button:disabled {
  background-color: #fafafc;
  cursor: not-allowed;
}
.button:disabled:hover {
  background-color: #fafafc;
}
</style>
